import { useEffect, useMemo, useState } from 'react'
import { useLocation, useMatches } from 'react-router-dom'
import { useTypedParams } from 'react-router-typesafe-routes/dom'

import { FeatureFlag, useFeatureFlag, useOverrideFeatureFlags } from '@/packages/feature-flag/useFeatureFlag'
import { ROUTES } from '@/packages/router/routes'
import useLocalStorage from '@/hooks/useLocalStorage'

type QueryEngine = 'ts' | 'ff'

const FF_PREFIXES = ['/meta/', '/e2e-test/test-e2e-project', '/e2e-test/e2e-project-']

const ALERT_QUERY_ENGINE_LOCAL_STORAGE_KEY = 'alertQueryEngine'

export const useQueryEngine = () => {
  const isAlertRoute = useIsAlertRoute()
  const defaultValue = useDefaultQueryEngineValue(isAlertRoute)
  const [value, setValue] = useState<QueryEngine>(defaultValue)

  const { value: alertQueryEngine, set: setAlertQueryEngine } = useLocalStorage(
    ALERT_QUERY_ENGINE_LOCAL_STORAGE_KEY,
    'ts',
    (x): x is QueryEngine => ['ts', 'ff'].includes(x),
  )

  const { enableFlag, disableFlag } = useOverrideFeatureFlags()

  const set = (newValue: QueryEngine) => {
    if (isAlertRoute) {
      setAlertQueryEngine(newValue)
      setValue(newValue)
      return
    }

    if (newValue === 'ff') {
      enableFlag(FeatureFlag.FF_QUERY_FLAG)
    } else {
      disableFlag(FeatureFlag.FF_QUERY_FLAG)
    }
    setValue(newValue)
  }

  useEffect(() => {
    if (isAlertRoute) {
      setValue(alertQueryEngine)
    } else {
      setValue(defaultValue)
    }
  }, [alertQueryEngine, defaultValue, isAlertRoute])

  return [value, set] as const
}

function useDefaultQueryEngineValue(isAlertRoute: boolean) {
  const { pathname } = useLocation()
  const isFFQueryEnabled = useFeatureFlag(FeatureFlag.FF_QUERY_FLAG)
  const isFF = useMemo(() => {
    return FF_PREFIXES.some((prefix) => pathname.startsWith(prefix)) || isFFQueryEnabled
  }, [pathname, isFFQueryEnabled])

  return isAlertRoute ? 'ts' : isFF ? 'ff' : 'ts'
}

function useIsAlertRoute() {
  const matchesRoutes = useMatches()
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT)

  const isAlertRoute = useMemo(() => {
    const alertRouteParent = matchesRoutes.find(
      (match) => match.pathname === ROUTES.ORGANIZATION.PROJECT.ALERTS.buildPath({ organizationName, projectName }),
    )
    return matchesRoutes.some((match) => match.pathname === alertRouteParent?.pathname)
  }, [organizationName, projectName, matchesRoutes])
  return isAlertRoute
}
