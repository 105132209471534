export const humanReadableNumber = (value: number | string, decimals = 2): string => {
  const parsedValue = typeof value !== 'number' ? Number(value) : value

  if (isNaN(parsedValue)) {
    return 'Invalid Number'
  }

  if (parsedValue < 1000) {
    return parsedValue.toLocaleString()
  }

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['', 'K', 'M', 'B', 'T', 'Q']
  const i = Math.floor(Math.log(parsedValue) / Math.log(k))
  return parseFloat((parsedValue / Math.pow(k, i)).toFixed(dm)) + sizes[i]
}

const numberSizes = ['', 'K', 'M', 'B', 'T', 'Q'] as const

export function humanReadableNumberParts(
  value: number | string,
  decimals = 2,
): { value: string; size: (typeof numberSizes)[number] } {
  const parsedValue = typeof value !== 'number' ? Number(value) : value

  if (isNaN(parsedValue)) {
    return { value: 'Invalid number', size: '' }
  }

  if (parsedValue < 1000) {
    return { value: parsedValue.toLocaleString(), size: '' }
  }

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const i = Math.floor(Math.log(parsedValue) / Math.log(k))
  const fixedValue = (parsedValue / Math.pow(k, i)).toFixed(dm)
  const size = numberSizes[i]
  return { value: fixedValue, size }
}

export const numberFormatter = new Intl.NumberFormat('en-US')
