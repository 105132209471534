// imported from https://github.com/johnpolacek/date-range-picker-for-shadcn
'use client'

import { type FC, useState, useEffect, useRef } from 'react'
import { CheckIcon } from '@radix-ui/react-icons'
import { ChevronDownIcon } from 'lucide-react'

import { cn } from '@/packages/style'
import { DateRange, DateRangePickerValue } from '@/store/monitoring'
import { SimpleDuration, simpleDurationSchema, getSimpleDurationLabel } from '@/packages/time/simpleDuration'
import { getDefaultDateRangePickerValue } from '@/packages/time'

import { Button } from './shadcn/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from './shadcn/ui/popover'
import { Calendar } from './shadcn/ui/calendar'
import { DateInput } from './shadcn/ui/date-input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './shadcn/ui/select'

const presetDurations = [
  '15m',
  '30m',
  '1h',
  '3h',
  '6h',
  '12h',
  '1d',
  '3d',
  '7d',
  '14d',
  '30d',
] as const satisfies SimpleDuration[]

export type DateRangePresets = (typeof presetDurations)[number]

export const resolutionsByTimeWindow: Record<DateRangePresets, SimpleDuration> = {
  '15m': '30s', // 30 data points
  '30m': '1m', // 36 data points
  '1h': '2m', // 30 data points
  '3h': '6m', // 30 data points
  '6h': '10m', // 36 data points
  '12h': '20m', // 36 data points
  '1d': '40m', // 36 data points
  '3d': '2h', // 36 data points
  '7d': '4h', // 42 data points
  '14d': '8h', // 42 data points
  '30d': '12h', // 60 data points
}

// Define presets
export const PRESETS = presetDurations.map((duration) => ({
  duration,
  label: getSimpleDurationLabel(duration, true),
}))

export interface DateRangePickerProps {
  triggerClassName?: string // TODO: I think we should remove this prop when we rework this component
  /** Click handler for applying the updates from DateRangePicker. */
  onUpdate?: (values: DateRangePickerValue) => void
  /** Value for DateRangePicker */
  value?: DateRangePickerValue
  /** Alignment of popover */
  align?: 'start' | 'center' | 'end'
  /** Option for locale */
  locale?: string
}

const formatDate = (date: string, locale = 'en-us'): string => {
  const dateObj = new Date(date)
  return dateObj.toLocaleDateString(locale, {
    minute: 'numeric',
    hour: 'numeric',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
}

const formatDateShort = (date: string, locale = 'en-us'): string => {
  const dateObj = new Date(date)
  return dateObj.toLocaleDateString(locale, {
    minute: '2-digit',
    hour: '2-digit',
    hour12: false,
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  })
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
  triggerClassName,
  value,
  onUpdate,
  align = 'end',
  locale = 'en-US',
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const [range, setRange] = useState<DateRange>({
    from: value?.range.from ?? new Date().toISOString(),
    to: value?.range.to,
  })

  const openedRangeRef = useRef<DateRange | undefined>()

  const [selectedPreset, setSelectedPreset] = useState<SimpleDuration | undefined>(value?.preset)

  const [isSmallScreen, setIsSmallScreen] = useState(typeof window !== 'undefined' ? window.innerWidth < 960 : false)

  useEffect(() => {
    const handleResize = (): void => {
      setIsSmallScreen(window.innerWidth < 960)
    }

    window.addEventListener('resize', handleResize)

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const setPreset = (preset?: DateRangePresets): void => {
    const { range: newRange } = getDefaultDateRangePickerValue(preset ?? '30m')
    setSelectedPreset(preset)
    setRange(newRange)
    onUpdate?.({ range: newRange, preset })

    if (preset) {
      setIsOpen(false)
    }
  }

  const PresetButton = ({
    preset,
    label,
    isSelected,
  }: {
    preset?: DateRangePresets
    label: string
    isSelected: boolean
  }) => (
    <Button
      className={cn(
        isSelected && 'pointer-events-none',
        'flex w-full items-start justify-start text-left text-sm font-normal',
      )}
      variant="ghost"
      onClick={() => {
        setPreset(preset)
      }}
    >
      <>
        <span className={cn('pr-2 opacity-0', isSelected && 'opacity-70')}>
          <CheckIcon width={18} height={18} />
        </span>
        {label}
      </>
    </Button>
  )

  // Helper function to check if two date ranges are equal
  const areRangesEqual = (a?: DateRange, b?: DateRange) => {
    if (!a || !b) return a === b // If either is undefined, return true if both are undefined
    const aFrom = new Date(a.from)
    const aTo = new Date(a.to ?? new Date())
    const bFrom = new Date(b.from ?? new Date())
    const bTo = new Date(b.to ?? new Date())

    return aFrom.getTime() === bFrom.getTime() && (!a.to || !b.to || aTo.getTime() === bTo.getTime())
  }

  useEffect(() => {
    if (isOpen) {
      openedRangeRef.current = range
    }
  }, [isOpen, range])

  return (
    <Popover
      modal={true}
      open={isOpen}
      onOpenChange={(open: boolean) => {
        if (!open) {
          if (!selectedPreset && !areRangesEqual(range, openedRangeRef.current)) {
            onUpdate?.({ range, preset: selectedPreset })
          }
        }
        setIsOpen(open)
      }}
    >
      <PopoverTrigger asChild>
        <Button
          size="lg"
          className={cn(
            'flex h-8 justify-between bg-surface-bright text-xs text-on-surface placeholder:text-on-surface hover:bg-surface-bright hover:text-on-surface',
            triggerClassName,
          )}
        >
          <div className="text-right">
            <div className={cn('py-1')}>
              <div>
                {!selectedPreset
                  ? isSmallScreen
                    ? `${formatDateShort(range.from, locale)}${
                        range.to != null ? ' - ' + formatDateShort(range.to, locale) : ''
                      }`
                    : `${formatDate(range.from, locale)}${
                        range.to != null ? ' - ' + formatDate(range.to, locale) : ''
                      }`
                  : PRESETS.find((preset) => preset.duration === selectedPreset)?.label}
              </div>
            </div>
          </div>
          <div className={cn('opacity-50 transition-transform', isOpen ? '-scale-y-100' : '')}>
            <ChevronDownIcon className="h-4 w-4" />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent align={align} className="text-sx max-h-[60vh] w-auto overflow-y-auto p-1">
        <div className="flex">
          <div className="flex">
            {(isSmallScreen || !selectedPreset) && (
              <div className="flex flex-col">
                <div className="flex flex-col items-center justify-center gap-2 px-3 lg:flex-row lg:items-start lg:pb-0">
                  {isSmallScreen && (
                    <Select
                      defaultValue={selectedPreset}
                      onValueChange={(value) => {
                        if (value === 'custom') {
                          setPreset(undefined)
                        } else {
                          // TODO: see if we can remove the `as`
                          setPreset(simpleDurationSchema.safeParse(value).data as DateRangePresets)
                        }
                      }}
                    >
                      <SelectTrigger className="mx-auto mb-2 w-[180px]">
                        <SelectValue placeholder="Select..." />
                      </SelectTrigger>
                      <SelectContent className="text-xs text-red-500">
                        {PRESETS.map((preset) => (
                          <SelectItem className="text-xs" key={preset.duration} value={preset.duration}>
                            {preset.label}
                          </SelectItem>
                        ))}
                        <SelectItem value="custom">Custom Range</SelectItem>
                      </SelectContent>
                    </Select>
                  )}
                  {!selectedPreset && (
                    <div className="flex flex-col gap-2">
                      <div
                        className={cn('flex gap-2', {
                          'flex-col': isSmallScreen,
                        })}
                      >
                        <DateInput
                          value={new Date(range.from)}
                          onChange={(date) => {
                            const toDate = range.to == null || date > new Date(range.to) ? date : new Date(range.to)
                            setRange((prevRange) => ({
                              ...prevRange,
                              from: date.toISOString(),
                              to: toDate.toISOString(),
                            }))
                          }}
                        />
                        {!isSmallScreen && <div className="py-1">-</div>}
                        <DateInput
                          value={range.to ? new Date(range.to) : undefined}
                          onChange={(date) => {
                            const fromDate = date < new Date(range.from) ? date : new Date(range.from)
                            setRange((prevRange) => ({
                              ...prevRange,
                              from: fromDate.toISOString(),
                              to: date.toISOString(),
                            }))
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {!selectedPreset && (
                  <div>
                    <Calendar
                      mode="range"
                      onSelect={(value: { from?: Date; to?: Date } | undefined) => {
                        if (value?.from != null) {
                          setRange({ from: value.from.toISOString(), to: value?.to?.toISOString() })
                        }
                      }}
                      selected={{
                        from: new Date(range.from),
                        to: range.to ? new Date(range.to) : undefined,
                      }}
                      numberOfMonths={isSmallScreen ? 1 : 2}
                      defaultMonth={new Date(new Date().setMonth(new Date().getMonth() - (isSmallScreen ? 0 : 1)))}
                    />
                    <div className="flex justify-end">
                      <Button
                        className="mt-2"
                        onClick={() => {
                          setIsOpen(false)
                          onUpdate?.({ range, preset: selectedPreset })
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {!isSmallScreen && (
            <div className="flex flex-col items-end gap-1">
              <div className="flex w-full flex-col items-start">
                {PRESETS.map((preset) => (
                  <PresetButton
                    key={preset.duration}
                    preset={preset.duration}
                    label={preset.label}
                    isSelected={selectedPreset === preset.duration}
                  />
                ))}
                <PresetButton preset={undefined} label="Custom Range" isSelected={selectedPreset === undefined} />
              </div>
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  )
}

DateRangePicker.displayName = 'DateRangePicker'
