import { Link } from 'react-router-dom'
import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { Trash2Icon } from 'lucide-react'
import { useState } from 'react'

import { Button, buttonVariants } from '@/components/shadcn/ui/button'
import { cn } from '@/packages/style'
import { ROUTES } from '@/packages/router/routes'
import {
  getProjectsGetReadTokensQueryKey,
  queryClient,
  useProjectsDeleteReadToken,
  useProjectsGetReadTokens,
} from '@/packages/api'
import { useToast } from '@/components/shadcn/ui/use-toast'
import { Dialog, DialogContent } from '@/components/shadcn/ui/dialog'
import { usePageTitle } from '@/hooks/usePageTitle'
import { LoadingButton } from '@/components/Button'
import { Alert } from '@/components/shadcn/ui/alert'
import { Badge } from '@/components/shadcn/ui/badge'
import { EmptyStateCard } from '@/components/EmptyStateContainer'
import { TipsCard } from '@/components/TipsContainer'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/shadcn/ui/table'

export const ProjectSettingsReadTokens = () => {
  usePageTitle('Read Tokens')
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.SETTINGS.READ_TOKENS.NEW)
  const readTokensQuery = useProjectsGetReadTokens(organizationName, projectName)
  const readTokens = readTokensQuery.data?.data ?? []
  const hasTokens = readTokens.length > 0
  const deleteReadTokenMutation = useProjectsDeleteReadToken({
    mutation: {
      onSuccess: () => {
        const readTokensKey = getProjectsGetReadTokensQueryKey(organizationName, projectName)
        queryClient.invalidateQueries({ queryKey: readTokensKey })
      },
    },
  })

  const [deleteToken, setDeleteToken] = useState<{ isOpen: boolean; tokenId: string | null }>({
    isOpen: false,
    tokenId: null,
  })
  const { toast } = useToast()

  const hasError = deleteReadTokenMutation.isError

  return (
    <>
      <Dialog
        open={deleteToken.isOpen}
        onOpenChange={(open) => {
          setDeleteToken({ isOpen: open, tokenId: null })
          deleteReadTokenMutation.reset()
        }}
      >
        <DialogContent>
          <h1 className="text-2xl font-bold">Delete token?</h1>
          <section className="space-y-5">
            <p className="text-on-surface">
              Are you sure you want to delete this token? This action cannot be undone.
            </p>
            <LoadingButton
              isLoading={deleteReadTokenMutation.isPending}
              onClick={() => {
                if (!deleteToken.tokenId) {
                  return toast({
                    variant: 'destructive',
                    title: 'Uh oh! Something went wrong.',
                    description: 'No token id provided',
                  })
                }
                deleteReadTokenMutation
                  .mutateAsync({
                    organization: organizationName,
                    project: projectName,
                    tokenId: deleteToken.tokenId,
                  })
                  .then(() => {
                    setDeleteToken({ isOpen: false, tokenId: null })
                  })
              }}
              variant="destructive"
            >
              Delete
            </LoadingButton>
          </section>
          {hasError && (
            <section>
              <Alert variant="destructive">There is an error while deleting the token. Please try again.</Alert>
            </section>
          )}
        </DialogContent>
      </Dialog>
      <section className="flex items-center justify-between">
        <Link
          to={ROUTES.ORGANIZATION.PROJECT.SETTINGS.READ_TOKENS.NEW.buildPath({ organizationName, projectName })}
          className={cn(buttonVariants({ variant: 'default' }))}
        >
          New read token
        </Link>
      </section>
      <section className="mt-4">
        <TipsCard
          className="mb-4"
          description="Read tokens are used to programmatically read data from your project."
          learnMoreLink="https://docs.pydantic.dev/logfire/guides/advanced/query_api/"
        />
        {!hasTokens && (
          <EmptyStateCard description={"You don't have any read tokens. Create one to view it in this list."} />
        )}
        {hasTokens && (
          <Table>
            <TableHeader>
              <TableRow className="uppercase hover:bg-inherit">
                <TableHead className="pl-2">Read Tokens</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {readTokens.map(({ id, description, token }) => {
                return (
                  <TableRow key={id} className="hover:bg-inherit">
                    <TableCell className="w-full pl-2">
                      {description}
                      <Badge className="ml-2 bg-on-surface-variant py-0.5 pt-0">{token}...</Badge>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          setDeleteToken({ isOpen: true, tokenId: id })
                        }}
                        className="flex gap-x-1 p-2"
                        variant="destructive"
                        size="sm"
                      >
                        <span>
                          <Trash2Icon className="h-4 w-4" />
                        </span>
                        <span>Delete</span>
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
      </section>
    </>
  )
}
