export const PydanticLogo = (props: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" {...props}>
      <path
        fill="currentColor"
        d="M119.18 86.64 98.02 57.3 63.77 9.8c-1.74-2.4-5.76-2.4-7.49 0L22.04 57.29.87 86.64c-.86 1.2-1.1 2.73-.65 4.13.46 1.4 1.55 2.5 2.95 2.96l55.41 18.14h.01c.46.15.94.23 1.43.23s.97-.08 1.43-.23h.01l55.41-18.14c1.4-.46 2.5-1.55 2.95-2.96.46-1.4.22-2.93-.65-4.13zM60.03 20.39l22.21 30.8-20.77-6.8c-.16-.05-.33-.04-.49-.08-.16-.04-.32-.06-.48-.08-.16-.02-.31-.08-.47-.08-.16 0-.31.06-.47.08-.17.02-.32.04-.48.08-.16.03-.33.03-.48.08l-20.64 6.76-.13.04 22.21-30.8zM28.65 63.91l24.18-7.92 2.58-.84v45.97l-43.35-14.2Zm36 37.2V55.15l26.76 8.76 16.59 23z"
      />
    </svg>
  )
}
