import { useEffect, useMemo } from 'react'
import { useTypedParams } from 'react-router-typesafe-routes/dom'

import useLocalStorage from '@/hooks/useLocalStorage'
import { useGetPlatformConfig } from '@/packages/api'
import { getStringHash } from '@/packages/utils/stringToHashNumber'
import { ROUTES } from '@/packages/router/routes'
import { useAuth } from '@/context/auth'

export enum FeatureFlag {
  FF_QUERY_FLAG = 'ff_query_rollout',
  // The following flags are just used for testing purposes
  TEST_DISABLED_FLAG = 'test_disabled_flag',
  TEST_PARTIAL_ROLLOUT_FLAG = 'test_partial_rollout_flag',
  TEST_ENABLED_FLAG = 'test_enabled_flag',
}

export const LOCAL_STORAGE_KEY = 'featureFlags'
export const LOCAL_DISABLED_STORAGE_KEY = 'disableFeatureFlags'
export const ENABLE_FLAGS_URL_HASH_KEY = 'enableFlags'
export const DISABLE_FLAGS_URL_HASH_KEY = 'disableFlags'

// Rollout map structure: feature flags with their corresponding rollout percentage
const defaultRolloutMap: Record<FeatureFlag, number> = {
  [FeatureFlag.FF_QUERY_FLAG]: 0,
  [FeatureFlag.TEST_DISABLED_FLAG]: 0,
  [FeatureFlag.TEST_PARTIAL_ROLLOUT_FLAG]: 50,
  [FeatureFlag.TEST_ENABLED_FLAG]: 100,
}

/**
 * Fetches the feature flags from the local storage and url
 *
 * This hook is used to fetch the feature flags from the url hash and store it in local storage or remove it from local storage
 * It also reads the feature flags from the local storage and returns a boolean value if the feature flag is enabled or not
 * the url sample: https://url#enableFlags=payment,shipping&disableFlags=payment-beta
 */
export const useFeatureFlag = (flag: FeatureFlag): boolean => {
  useEffect(() => {
    initFeatureFlags()
  }, [])

  const userNumberForFeatureFlag = useUserUniqueNumberForFlag(flag)
  const rolloutFlagPercentage = useRolloutFlagPercentage(flag)

  const { enableFlags } = useEnabledFlagsFromLocalStorage()
  const { disableFlags } = useDisabledFlagsFromLocalStorage()

  const urlOverrides = getUrlOverrides() // Fetch flags from URL if available

  if (urlOverrides.enableFlags.includes(flag) || enableFlags.includes(flag) || rolloutFlagPercentage === 100) {
    return true
  }

  if (urlOverrides.disableFlags.includes(flag) || disableFlags.includes(flag) || rolloutFlagPercentage === 0) {
    return false
  }

  return userNumberForFeatureFlag <= rolloutFlagPercentage
}

/**
 * Fetches flag overrides from the URL (if any)
 * This function parses the URL hash to get enableFlags and disableFlags
 */
const getUrlOverrides = () => {
  const url = new URL(window.location.href)
  const parsedHash = new URLSearchParams(url.hash.substring(1))
  const enableFlagsHash = parsedHash.get(ENABLE_FLAGS_URL_HASH_KEY)
  const disableFlagHash = parsedHash.get(DISABLE_FLAGS_URL_HASH_KEY)

  const enableFlags = (enableFlagsHash?.split(',') ?? []) as FeatureFlag[]
  const disableFlags = (disableFlagHash?.split(',') ?? []) as FeatureFlag[]

  return { enableFlags, disableFlags }
}

export const initFeatureFlags = () => {
  const url = new URL(window.location.href)
  const parsedHash = new URLSearchParams(url.hash.substring(1))
  const enableFlagsHash = parsedHash.get(ENABLE_FLAGS_URL_HASH_KEY)
  const disableFlagHash = parsedHash.get(DISABLE_FLAGS_URL_HASH_KEY)

  const enableFlags = enableFlagsHash?.split(',') ?? []
  const disableFlags = disableFlagHash?.split(',') ?? []

  try {
    const localEnabledFlags = new Set(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '[]'))

    // remove the disable flags from the flags
    disableFlags.forEach((flag) => localEnabledFlags.delete(flag))

    // add the enable flags to the flags
    enableFlags.forEach((flag) => localEnabledFlags.add(flag))

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(Array.from(localEnabledFlags)))

    const localDisabledFlags = new Set(JSON.parse(localStorage.getItem(LOCAL_DISABLED_STORAGE_KEY) ?? '[]'))

    // remove the enable flags from the flags
    enableFlags.forEach((flag) => localEnabledFlags.delete(flag))

    // add the disable flags to the flags
    disableFlags.forEach((flag) => localEnabledFlags.add(flag))

    localStorage.setItem(LOCAL_DISABLED_STORAGE_KEY, JSON.stringify(Array.from(localDisabledFlags)))
  } catch (e) {
    console.error('Error while setting the feature flags', e)
  }
}

export const useOverrideFeatureFlags = () => {
  const { enableFlags, setEnableFlags } = useEnabledFlagsFromLocalStorage()
  const { disableFlags, setDisableFlags } = useDisabledFlagsFromLocalStorage()

  function enableFlag(flag: FeatureFlag) {
    setDisableFlags(disableFlags.filter((f) => f !== flag))

    if (!enableFlags.includes(flag)) {
      setEnableFlags([...enableFlags, flag])
    }

    window.location.reload()
  }

  function disableFlag(flag: FeatureFlag) {
    setEnableFlags(enableFlags.filter((f) => f !== flag))

    if (!disableFlags.includes(flag)) {
      setDisableFlags([...disableFlags, flag])
    }

    window.location.reload()
  }

  return { enableFlag, disableFlag }
}

function useRolloutFlagPercentage(flag: FeatureFlag) {
  const { data: platformConfigResponse } = useGetPlatformConfig({
    query: {
      refetchInterval: 60 * 1000, // refetch every minute
    },
  })

  const platformConfig = platformConfigResponse?.data

  const map = useMemo<typeof defaultRolloutMap>(() => {
    if (!platformConfig) {
      return defaultRolloutMap
    }

    return {
      ...defaultRolloutMap,
      [FeatureFlag.FF_QUERY_FLAG]: platformConfig.ff_query_rollout ?? 0,
    }
  }, [platformConfig])
  return map[flag]
}

function useUserUniqueNumberForFlag(flag: FeatureFlag) {
  const { organizationName } = useTypedParams(ROUTES.ORGANIZATION)
  const { user } = useAuth()

  return useMemo(() => {
    const uuid = user?.id ?? '' + '-' + organizationName
    return (getStringHash(`${uuid}:${flag}`) % 100) + 1
  }, [flag, organizationName, user?.id])
}

function useEnabledFlagsFromLocalStorage() {
  const { value: enableFlags, set: setEnableFlags } = useLocalStorage<FeatureFlag[]>(
    LOCAL_STORAGE_KEY,
    [],
    featureFlagTypeGuard,
  )
  return { enableFlags, setEnableFlags }
}

function useDisabledFlagsFromLocalStorage() {
  const { value: disableFlags, set: setDisableFlags } = useLocalStorage<FeatureFlag[]>(
    LOCAL_DISABLED_STORAGE_KEY,
    [],
    featureFlagTypeGuard,
  )
  return { disableFlags, setDisableFlags }
}

function featureFlagTypeGuard(x: any): x is FeatureFlag[] {
  return Array.isArray(x)
}
