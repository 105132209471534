import { UserSheet } from '@/components/UserSheet'
import { LogfireIcon } from '@/components/LogfireIcon'

import { Breadcrumbs } from './Breadcrumbs'
import { DarkModeToggle } from './DarkModeToggle'
import { BetaModal } from './BetaModal/BetaModal'
import { Feedback } from './Feedback'

export const Navbar = () => {
  return (
    <nav className="sticky top-0 z-40 flex w-full shrink-0 items-center justify-between border-b border-outline bg-surface px-7 text-sm font-[450]">
      <section className="flex items-center gap-x-2">
        <section className="flex items-center gap-x-2">
          <div className="relative bottom-[8px] flex flex-col items-center justify-center">
            <div className="relative z-0 h-4 w-[1px] border-l-[0.5px] border-outline bg-on-surface/20" />
            <LogfireIcon className="z-10 h-3 w-3 select-none text-logfire dark:text-logfire" />
          </div>
          <Breadcrumbs />
        </section>
      </section>
      <section className="flex h-10 items-center gap-x-4">
        <Feedback />
        <BetaModal />
        <DarkModeToggle />
        <UserSheet />
      </section>
    </nav>
  )
}
