import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useTypedState } from 'react-router-typesafe-routes/dom'
import { jwtDecode } from 'jwt-decode'
import { ExternalLinkIcon, InfoIcon } from 'lucide-react'

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/shadcn/ui/tooltip'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/shadcn/ui/form'
import { useCheckOrganizationName } from '@/app/organization/utils/useCheckOrganizationName'
import { useCreateGithubUser, useGetPlatformConfig } from '@/packages/api'
import { ROUTES } from '@/packages/router/routes'
import { Checkbox } from '@/components/shadcn/ui/checkbox'
import { Input } from '@/components/shadcn/ui/input'
import { BlockRegisterMessage } from '@/components/BlockRegisterMessage'
import { LoadingButton } from '@/components/Button'
import { extractError } from '@/utils/extract-error'
import { LogfireLogoWithBetaBadge } from '@/components/LogfireLogoWithBetaBadge'
import { usePageTitle } from '@/hooks/usePageTitle'
import { PydanticLogo } from '@/components/PydanticLogo'

const formSchema = z.object({
  username: z
    .string()
    .min(1, { message: 'Please enter a username' })
    .min(2, { message: 'Your username must have at least 2 characters' })
    .max(50, { message: 'Your username must have at most 50 characters' })
    .regex(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, {
      message:
        'This is an invalid username. Usernames may only contain lowercase alphanumeric characters or single hyphens, and cannot begin or end with a hyphen.',
    }),
  allowMarketing: z.boolean(),
  tosAccepted: z.boolean().refine((val) => val === true, { message: 'Please accept Terms & Conditions' }),
})

type FormValues = z.infer<typeof formSchema>

export const AcceptTOS = () => {
  usePageTitle('Terms of Service')

  const navigate = useNavigate()

  const { userInfoJWT, state } = useTypedState(ROUTES.AUTH.ACCEPT_TOS)
  const decoded: { username: string; email?: string } = jwtDecode(userInfoJWT)

  const { data: platformConfig } = useGetPlatformConfig()

  const form = useForm<FormValues>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: { username: decoded.username, allowMarketing: true, tosAccepted: false },
  })
  const name = form.watch('username') ?? ''
  const { isSuccess, isAvailable, isPending } = useCheckOrganizationName(name)

  useEffect(() => {
    if (form.getFieldState('username').invalid) return
    const username = form.getValues('username')
    if (!isSuccess) return

    if (isPending || isAvailable) {
      form.clearErrors('username')
    } else {
      // Note: Even though we are displaying whether the _username_ is available, it is critical that we are checking
      // whether there is an existing _organization_ with the name, rather than an existing _user_ with the name.
      // This is because we always create an organization with the same name as the user's username.
      form.setError('username', { message: `The username '${username}' is not available` })
    }
  }, [form, isSuccess, isPending, isAvailable])

  const mutation = useCreateGithubUser()
  const isMutationPending = mutation.isPending

  const onSubmit = (data: FormValues) => {
    if (!data.tosAccepted) {
      return form.setError('tosAccepted', { message: 'Please accept Terms & Conditions' })
    }
    mutation.mutate(
      { data: { username: data.username, user_info_jwt: userInfoJWT, allow_marketing: data.allowMarketing } },
      {
        onSuccess: ({ data }) => {
          navigate(ROUTES.AUTH.AUTHENTICATE.path, { state: ROUTES.AUTH.AUTHENTICATE.buildState({ ...data, state }) })
        },
        onError: (error) => {
          const errorMessage = extractError(error.response?.data.detail, 'An error occurred, please try again.')
          return form.setError('username', { message: errorMessage })
        },
      },
    )
  }

  if (platformConfig?.data.signup === 'disallow') {
    return (
      <section className="no-footer fixed-height grid h-full w-full place-content-center">
        <BlockRegisterMessage />
      </section>
    )
  }

  return (
    <section className="no-footer flex h-full flex-col items-center justify-center bg-surface">
      <section className="fixed-height grid h-full w-full place-content-center">
        <section className="mb-8 flex w-full flex-col justify-center">
          <LogfireLogoWithBetaBadge badgeClassName="bg-logfire" className="justify-center text-logfire" />
        </section>
        <section className="mx-4 flex max-w-lg flex-col items-start gap-y-3 rounded-t-lg border border-outline border-b-transparent bg-surface px-6 py-5 sm:mx-6 md:px-14 md:pt-10">
          <p className="block w-full text-center text-base font-medium">Create Account</p>
          <p className="text-sm font-medium leading-none">
            Logfire email
            <span className="block text-base font-light opacity-70">{decoded?.email}</span>
          </p>
          <p className="text-sm font-medium leading-none">
            GitHub username
            <span className="block text-base font-light opacity-70">@{decoded?.username}</span>
          </p>
          <p className="flex items-center text-xs opacity-70">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-1 h-3.5 w-3.5"
            >
              <path d="M15 2H9a1 1 0 0 0-1 1v2c0 .6.4 1 1 1h6c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1Z" />
              <path d="M8 4H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2M16 4h2a2 2 0 0 1 2 2v2M11 14h10" />
              <path d="m17 10 4 4-4 4" />
            </svg>
            Auto-populated from your GitHub account
          </p>
        </section>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="mx-4 flex max-w-lg flex-col items-start rounded-b-lg border border-outline border-t-transparent bg-surface-container px-6 py-5 sm:mx-6 md:px-14 md:pb-10"
          >
            <section className="mb-3 flex flex-col gap-y-3">
              <FormField
                control={form.control}
                name="username"
                render={({ field, fieldState }) => {
                  return (
                    <FormItem>
                      <FormLabel>Pick a username</FormLabel>
                      <FormDescription className="flex items-center">
                        <InfoIcon className="mr-1 h-3 w-3" />
                        Human-friendly name, used as a page slug
                      </FormDescription>
                      <FormControl className="x-full">
                        <Input value={field.value} onChange={field.onChange} />
                      </FormControl>
                      {fieldState.invalid ? (
                        <FormMessage className="max-w-[350px]" />
                      ) : field.value && isPending ? (
                        <FormDescription className="truncate pt-0.5">
                          Checking availability for <code>{name}</code>...
                        </FormDescription>
                      ) : (
                        <FormDescription className="truncate pt-0.5">
                          logfire.pydantic.dev/
                          <span className="font-medium text-states-info-variant">
                            {field.value && field.value.length > 0 ? field.value : '...'}
                          </span>
                        </FormDescription>
                      )}
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name="allowMarketing"
                render={({ field }) => {
                  return (
                    <FormItem className="mt-4 flex items-center gap-x-2 space-y-0 pl-0.5">
                      <FormControl>
                        <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                      </FormControl>
                      <div className="relative flex flex-col gap-y-2">
                        <FormLabel className="leading-5">Receive occasional updates and announcements</FormLabel>
                      </div>
                      <FormMessage className="absolute bottom-0 left-0 translate-y-full transform" />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name="tosAccepted"
                render={({ field }) => {
                  return (
                    <FormItem className="mb-4 pl-0.5">
                      <div className="flex items-center gap-x-2 space-y-0">
                        <FormControl>
                          <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                        </FormControl>
                        <div className="relative flex flex-col gap-y-2">
                          <FormLabel className="leading-5">
                            I agree to{' '}
                            <a
                              className="underline"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://docs.pydantic.dev/logfire/legal/terms_of_service/"
                            >
                              Terms of Service
                            </a>{' '}
                            and the{' '}
                            <a
                              href="https://docs.pydantic.dev/logfire/legal/privacy/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="underline"
                            >
                              Privacy Policy
                            </a>
                          </FormLabel>
                        </div>
                      </div>
                      <FormMessage className="" />
                    </FormItem>
                  )
                }}
              />
            </section>
            <LoadingButton isLoading={isMutationPending} type="submit" className="w-full gap-x-2">
              Create account
            </LoadingButton>
          </form>
        </Form>
        <Links />
      </section>
      <BabyFooter />
    </section>
  )
}

const Links = () => {
  return (
    <section className="flex justify-center gap-x-4 pt-4 text-xs">
      <a
        href="https://docs.pydantic.dev/logfire/guides/"
        target="_blank"
        rel="noreferrer noopener"
        className="flex items-center gap-x-1 underline transition-opacity hover:opacity-50"
      >
        Docs <ExternalLinkIcon className="h-3 w-3" />
      </a>
      <a
        href="https://join.slack.com/t/pydanticlogfire/shared_invite/zt-2b57ljub4-936siSpHANKxoY4dna7qng"
        target="_blank"
        rel="noreferrer noopener"
        className="flex items-center gap-x-1 underline transition-opacity hover:opacity-50"
      >
        Slack <ExternalLinkIcon className="h-3 w-3" />
      </a>
      <a
        href="https://logfirestatus.pydantic.dev/"
        target="_blank"
        rel="noreferrer noopener"
        className="flex items-center gap-x-1 underline transition-opacity hover:opacity-50"
      >
        System status <ExternalLinkIcon className="h-3 w-3" />
      </a>
    </section>
  )
}

const BabyFooter = () => {
  return (
    <section className="flex w-full shrink-0 flex-col items-center justify-center py-5 md:mb-20">
      <p className="mb-1 text-center">
        <Tooltip>
          <TooltipContent>Discover what sets Logfire apart</TooltipContent>
          <TooltipTrigger>
            <a
              href="https://docs.pydantic.dev/logfire/"
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center font-mono text-lg font-extralight text-logfire transition hover:text-link sm:text-xl"
            >
              Uncomplicated observability
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="ml-1 h-5 w-5"
              >
                <path d="M13 5H19V11" />
                <path d="M19 5L5 19" />
              </svg>
            </a>
          </TooltipTrigger>
        </Tooltip>
      </p>
      <p className="flex items-center gap-x-2">
        Built by the team behind
        <span className="flex items-center gap-x-1 text-xl font-bold">
          <PydanticLogo className="h-6 w-6 select-none text-logfire" /> Pydantic
        </span>
      </p>
    </section>
  )
}
