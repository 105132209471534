import { useEffect, useRef } from 'react'
import slugify from '@sindresorhus/slugify'

import { useOrganizationsCheckName } from '@/packages/api'

export const useCheckOrganizationName = (displayName: string) => {
  const { mutate, isPending, isSuccess, data, isIdle, reset } = useOrganizationsCheckName()
  const timeRef = useRef<NodeJS.Timer>()
  useEffect(() => {
    reset()

    if (timeRef.current) {
      clearTimeout(timeRef.current)
    }

    timeRef.current = setTimeout(() => {
      if (!displayName) {
        return
      }
      mutate({ data: { organization_name: slugify(displayName) } })
      timeRef.current = undefined
    }, 1000)
  }, [timeRef, mutate, displayName, reset])

  return {
    isPending: isPending || Boolean(timeRef.current),
    isSuccess,
    isAvailable: Boolean(data?.data.available),
    isIdle,
  }
}
