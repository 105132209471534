import { TypesElement } from '@uiw/react-json-view'
import { useState } from 'react'

import { cn } from '@/packages/style'

/** This is the display of a string-like value */
export const RenderString = (props: TypesElement<'span'>) => {
  const { children, className, onClick, ...otherProps } = props

  const escapedValue = String(children).replaceAll(/"/g, '\\"')

  // Ensure we don't toggle collapsed if the mouse is dragged:
  const [isDragging, setIsDragging] = useState(false)
  const handleMouseDown = () => {
    setIsDragging(false) // Reset drag state when mouse is pressed down
  }

  const handleMouseMove = () => {
    setIsDragging(true) // Set dragging state when mouse is moved
  }

  const handleMouseUp = (e: any) => {
    if (!isDragging && onClick) {
      onClick(e) // Call the click handler only if there was no dragging
    }
  }

  const spanProps = {
    ...otherProps,
    onMouseDown: handleMouseDown,
    onMouseMove: handleMouseMove,
    onMouseUp: handleMouseUp,
  }

  return (
    <span {...spanProps} className={cn(className, 'whitespace-pre-wrap')}>
      &quot;{escapedValue}&quot;
    </span>
  )
}
