/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Logfire
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'
import * as axios from 'axios'
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import type {
  DoNotUseThisEndpointWebsocketStructGenerationGet200,
  DoNotUseThisEndpointWebsocketStructGenerationGetBody,
  FetchAncestorRecordsParams,
  FetchNonMatchingRecordsParams,
  FetchQueryRecordsParams,
  FetchRecordDetailsParams,
  FetchTraceCountsParams,
  FetchTraceGraphParams,
  FetchTraceParams,
  HTTPExceptionError,
  HTTPValidationError,
  HttpAncestorsRequest,
  HttpNonMatchingRequest,
  HttpQueryRequest,
  HttpQueryResponse,
  HttpRelatedResponse,
  HttpTraceCountsRequest,
  HttpTraceCountsResponse,
  HttpTraceRequest,
  HttpTraceResponse,
  RecordDetails,
  SpanLocator,
  TraceGraph,
} from '.././model'

/**
 * @summary Fetch Query Records
 */
export const fetchQueryRecords = (
  organization: string,
  project: string,
  httpQueryRequest: HttpQueryRequest,
  params?: FetchQueryRecordsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<HttpQueryResponse>> => {
  return axios.default.post(`/dash/organizations/${organization}/projects/${project}/fetch-query`, httpQueryRequest, {
    ...options,
    params: { ...params, ...options?.params },
  })
}

export const getFetchQueryRecordsMutationOptions = <
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchQueryRecords>>,
    TError,
    { organization: string; project: string; data: HttpQueryRequest; params?: FetchQueryRecordsParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof fetchQueryRecords>>,
  TError,
  { organization: string; project: string; data: HttpQueryRequest; params?: FetchQueryRecordsParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fetchQueryRecords>>,
    { organization: string; project: string; data: HttpQueryRequest; params?: FetchQueryRecordsParams }
  > = (props) => {
    const { organization, project, data, params } = props ?? {}

    return fetchQueryRecords(organization, project, data, params, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type FetchQueryRecordsMutationResult = NonNullable<Awaited<ReturnType<typeof fetchQueryRecords>>>
export type FetchQueryRecordsMutationBody = HttpQueryRequest
export type FetchQueryRecordsMutationError = AxiosError<HTTPExceptionError | HTTPValidationError>

/**
 * @summary Fetch Query Records
 */
export const useFetchQueryRecords = <
  TError = AxiosError<HTTPExceptionError | HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchQueryRecords>>,
    TError,
    { organization: string; project: string; data: HttpQueryRequest; params?: FetchQueryRecordsParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof fetchQueryRecords>>,
  TError,
  { organization: string; project: string; data: HttpQueryRequest; params?: FetchQueryRecordsParams },
  TContext
> => {
  const mutationOptions = getFetchQueryRecordsMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Fetch Ancestor Records
 */
export const fetchAncestorRecords = (
  organization: string,
  project: string,
  httpAncestorsRequest: HttpAncestorsRequest,
  params?: FetchAncestorRecordsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<HttpRelatedResponse>> => {
  return axios.default.post(
    `/dash/organizations/${organization}/projects/${project}/fetch-ancestors`,
    httpAncestorsRequest,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  )
}

export const getFetchAncestorRecordsMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchAncestorRecords>>,
    TError,
    { organization: string; project: string; data: HttpAncestorsRequest; params?: FetchAncestorRecordsParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof fetchAncestorRecords>>,
  TError,
  { organization: string; project: string; data: HttpAncestorsRequest; params?: FetchAncestorRecordsParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fetchAncestorRecords>>,
    { organization: string; project: string; data: HttpAncestorsRequest; params?: FetchAncestorRecordsParams }
  > = (props) => {
    const { organization, project, data, params } = props ?? {}

    return fetchAncestorRecords(organization, project, data, params, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type FetchAncestorRecordsMutationResult = NonNullable<Awaited<ReturnType<typeof fetchAncestorRecords>>>
export type FetchAncestorRecordsMutationBody = HttpAncestorsRequest
export type FetchAncestorRecordsMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Fetch Ancestor Records
 */
export const useFetchAncestorRecords = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchAncestorRecords>>,
    TError,
    { organization: string; project: string; data: HttpAncestorsRequest; params?: FetchAncestorRecordsParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof fetchAncestorRecords>>,
  TError,
  { organization: string; project: string; data: HttpAncestorsRequest; params?: FetchAncestorRecordsParams },
  TContext
> => {
  const mutationOptions = getFetchAncestorRecordsMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Fetch Non Matching Records
 */
export const fetchNonMatchingRecords = (
  organization: string,
  project: string,
  httpNonMatchingRequest: HttpNonMatchingRequest,
  params?: FetchNonMatchingRecordsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<HttpRelatedResponse>> => {
  return axios.default.post(
    `/dash/organizations/${organization}/projects/${project}/fetch-non-matching`,
    httpNonMatchingRequest,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  )
}

export const getFetchNonMatchingRecordsMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchNonMatchingRecords>>,
    TError,
    { organization: string; project: string; data: HttpNonMatchingRequest; params?: FetchNonMatchingRecordsParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof fetchNonMatchingRecords>>,
  TError,
  { organization: string; project: string; data: HttpNonMatchingRequest; params?: FetchNonMatchingRecordsParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fetchNonMatchingRecords>>,
    { organization: string; project: string; data: HttpNonMatchingRequest; params?: FetchNonMatchingRecordsParams }
  > = (props) => {
    const { organization, project, data, params } = props ?? {}

    return fetchNonMatchingRecords(organization, project, data, params, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type FetchNonMatchingRecordsMutationResult = NonNullable<Awaited<ReturnType<typeof fetchNonMatchingRecords>>>
export type FetchNonMatchingRecordsMutationBody = HttpNonMatchingRequest
export type FetchNonMatchingRecordsMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Fetch Non Matching Records
 */
export const useFetchNonMatchingRecords = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchNonMatchingRecords>>,
    TError,
    { organization: string; project: string; data: HttpNonMatchingRequest; params?: FetchNonMatchingRecordsParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof fetchNonMatchingRecords>>,
  TError,
  { organization: string; project: string; data: HttpNonMatchingRequest; params?: FetchNonMatchingRecordsParams },
  TContext
> => {
  const mutationOptions = getFetchNonMatchingRecordsMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Fetch Trace
 */
export const fetchTrace = (
  organization: string,
  project: string,
  httpTraceRequest: HttpTraceRequest,
  params?: FetchTraceParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<HttpTraceResponse>> => {
  return axios.default.post(`/dash/organizations/${organization}/projects/${project}/fetch-trace`, httpTraceRequest, {
    ...options,
    params: { ...params, ...options?.params },
  })
}

export const getFetchTraceMutationOptions = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchTrace>>,
    TError,
    { organization: string; project: string; data: HttpTraceRequest; params?: FetchTraceParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof fetchTrace>>,
  TError,
  { organization: string; project: string; data: HttpTraceRequest; params?: FetchTraceParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fetchTrace>>,
    { organization: string; project: string; data: HttpTraceRequest; params?: FetchTraceParams }
  > = (props) => {
    const { organization, project, data, params } = props ?? {}

    return fetchTrace(organization, project, data, params, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type FetchTraceMutationResult = NonNullable<Awaited<ReturnType<typeof fetchTrace>>>
export type FetchTraceMutationBody = HttpTraceRequest
export type FetchTraceMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Fetch Trace
 */
export const useFetchTrace = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchTrace>>,
    TError,
    { organization: string; project: string; data: HttpTraceRequest; params?: FetchTraceParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof fetchTrace>>,
  TError,
  { organization: string; project: string; data: HttpTraceRequest; params?: FetchTraceParams },
  TContext
> => {
  const mutationOptions = getFetchTraceMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Fetch Trace Counts
 */
export const fetchTraceCounts = (
  organization: string,
  project: string,
  httpTraceCountsRequest: HttpTraceCountsRequest,
  params?: FetchTraceCountsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<HttpTraceCountsResponse>> => {
  return axios.default.post(
    `/dash/organizations/${organization}/projects/${project}/fetch-trace-counts`,
    httpTraceCountsRequest,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  )
}

export const getFetchTraceCountsMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchTraceCounts>>,
    TError,
    { organization: string; project: string; data: HttpTraceCountsRequest; params?: FetchTraceCountsParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof fetchTraceCounts>>,
  TError,
  { organization: string; project: string; data: HttpTraceCountsRequest; params?: FetchTraceCountsParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fetchTraceCounts>>,
    { organization: string; project: string; data: HttpTraceCountsRequest; params?: FetchTraceCountsParams }
  > = (props) => {
    const { organization, project, data, params } = props ?? {}

    return fetchTraceCounts(organization, project, data, params, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type FetchTraceCountsMutationResult = NonNullable<Awaited<ReturnType<typeof fetchTraceCounts>>>
export type FetchTraceCountsMutationBody = HttpTraceCountsRequest
export type FetchTraceCountsMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Fetch Trace Counts
 */
export const useFetchTraceCounts = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchTraceCounts>>,
    TError,
    { organization: string; project: string; data: HttpTraceCountsRequest; params?: FetchTraceCountsParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof fetchTraceCounts>>,
  TError,
  { organization: string; project: string; data: HttpTraceCountsRequest; params?: FetchTraceCountsParams },
  TContext
> => {
  const mutationOptions = getFetchTraceCountsMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Fetch Trace Graph
 */
export const fetchTraceGraph = (
  organization: string,
  project: string,
  params: FetchTraceGraphParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<TraceGraph>> => {
  return axios.default.post(`/dash/organizations/${organization}/projects/${project}/fetch-trace-graph`, undefined, {
    ...options,
    params: { ...params, ...options?.params },
  })
}

export const getFetchTraceGraphMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchTraceGraph>>,
    TError,
    { organization: string; project: string; params: FetchTraceGraphParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof fetchTraceGraph>>,
  TError,
  { organization: string; project: string; params: FetchTraceGraphParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fetchTraceGraph>>,
    { organization: string; project: string; params: FetchTraceGraphParams }
  > = (props) => {
    const { organization, project, params } = props ?? {}

    return fetchTraceGraph(organization, project, params, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type FetchTraceGraphMutationResult = NonNullable<Awaited<ReturnType<typeof fetchTraceGraph>>>

export type FetchTraceGraphMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Fetch Trace Graph
 */
export const useFetchTraceGraph = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchTraceGraph>>,
    TError,
    { organization: string; project: string; params: FetchTraceGraphParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof fetchTraceGraph>>,
  TError,
  { organization: string; project: string; params: FetchTraceGraphParams },
  TContext
> => {
  const mutationOptions = getFetchTraceGraphMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Stream records to the client.
 * @summary Fetch Record Details
 */
export const fetchRecordDetails = (
  organization: string,
  project: string,
  spanLocator: SpanLocator[],
  params?: FetchRecordDetailsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<RecordDetails[]>> => {
  return axios.default.post(`/dash/organizations/${organization}/projects/${project}/fetch-details`, spanLocator, {
    ...options,
    params: { ...params, ...options?.params },
  })
}

export const getFetchRecordDetailsMutationOptions = <
  TError = AxiosError<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchRecordDetails>>,
    TError,
    { organization: string; project: string; data: SpanLocator[]; params?: FetchRecordDetailsParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationOptions<
  Awaited<ReturnType<typeof fetchRecordDetails>>,
  TError,
  { organization: string; project: string; data: SpanLocator[]; params?: FetchRecordDetailsParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fetchRecordDetails>>,
    { organization: string; project: string; data: SpanLocator[]; params?: FetchRecordDetailsParams }
  > = (props) => {
    const { organization, project, data, params } = props ?? {}

    return fetchRecordDetails(organization, project, data, params, axiosOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type FetchRecordDetailsMutationResult = NonNullable<Awaited<ReturnType<typeof fetchRecordDetails>>>
export type FetchRecordDetailsMutationBody = SpanLocator[]
export type FetchRecordDetailsMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Fetch Record Details
 */
export const useFetchRecordDetails = <TError = AxiosError<HTTPValidationError>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchRecordDetails>>,
    TError,
    { organization: string; project: string; data: SpanLocator[]; params?: FetchRecordDetailsParams },
    TContext
  >
  axios?: AxiosRequestConfig
}): UseMutationResult<
  Awaited<ReturnType<typeof fetchRecordDetails>>,
  TError,
  { organization: string; project: string; data: SpanLocator[]; params?: FetchRecordDetailsParams },
  TContext
> => {
  const mutationOptions = getFetchRecordDetailsMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Do Not Use This Endpoint
 */
export const doNotUseThisEndpointWebsocketStructGenerationGet = (
  doNotUseThisEndpointWebsocketStructGenerationGetBody: DoNotUseThisEndpointWebsocketStructGenerationGetBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DoNotUseThisEndpointWebsocketStructGenerationGet200>> => {
  return axios.default.get(`/websocket-struct-generation`, options)
}

export const getDoNotUseThisEndpointWebsocketStructGenerationGetQueryKey = (
  doNotUseThisEndpointWebsocketStructGenerationGetBody: DoNotUseThisEndpointWebsocketStructGenerationGetBody,
) => {
  return [`/websocket-struct-generation`, doNotUseThisEndpointWebsocketStructGenerationGetBody] as const
}

export const getDoNotUseThisEndpointWebsocketStructGenerationGetQueryOptions = <
  TData = Awaited<ReturnType<typeof doNotUseThisEndpointWebsocketStructGenerationGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  doNotUseThisEndpointWebsocketStructGenerationGetBody: DoNotUseThisEndpointWebsocketStructGenerationGetBody,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof doNotUseThisEndpointWebsocketStructGenerationGet>>, TError, TData>
    >
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getDoNotUseThisEndpointWebsocketStructGenerationGetQueryKey(doNotUseThisEndpointWebsocketStructGenerationGetBody)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof doNotUseThisEndpointWebsocketStructGenerationGet>>> = ({
    signal,
  }) =>
    doNotUseThisEndpointWebsocketStructGenerationGet(doNotUseThisEndpointWebsocketStructGenerationGetBody, {
      signal,
      ...axiosOptions,
    })

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof doNotUseThisEndpointWebsocketStructGenerationGet>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type DoNotUseThisEndpointWebsocketStructGenerationGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof doNotUseThisEndpointWebsocketStructGenerationGet>>
>
export type DoNotUseThisEndpointWebsocketStructGenerationGetQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Do Not Use This Endpoint
 */
export const useDoNotUseThisEndpointWebsocketStructGenerationGet = <
  TData = Awaited<ReturnType<typeof doNotUseThisEndpointWebsocketStructGenerationGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  doNotUseThisEndpointWebsocketStructGenerationGetBody: DoNotUseThisEndpointWebsocketStructGenerationGetBody,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof doNotUseThisEndpointWebsocketStructGenerationGet>>, TError, TData>
    >
    axios?: AxiosRequestConfig
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDoNotUseThisEndpointWebsocketStructGenerationGetQueryOptions(
    doNotUseThisEndpointWebsocketStructGenerationGetBody,
    options,
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getDoNotUseThisEndpointWebsocketStructGenerationGetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof doNotUseThisEndpointWebsocketStructGenerationGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  doNotUseThisEndpointWebsocketStructGenerationGetBody: DoNotUseThisEndpointWebsocketStructGenerationGetBody,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof doNotUseThisEndpointWebsocketStructGenerationGet>>,
        TError,
        TData
      >
    >
    axios?: AxiosRequestConfig
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getDoNotUseThisEndpointWebsocketStructGenerationGetQueryKey(doNotUseThisEndpointWebsocketStructGenerationGetBody)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof doNotUseThisEndpointWebsocketStructGenerationGet>>> = ({
    signal,
  }) =>
    doNotUseThisEndpointWebsocketStructGenerationGet(doNotUseThisEndpointWebsocketStructGenerationGetBody, {
      signal,
      ...axiosOptions,
    })

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof doNotUseThisEndpointWebsocketStructGenerationGet>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type DoNotUseThisEndpointWebsocketStructGenerationGetSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof doNotUseThisEndpointWebsocketStructGenerationGet>>
>
export type DoNotUseThisEndpointWebsocketStructGenerationGetSuspenseQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Do Not Use This Endpoint
 */
export const useDoNotUseThisEndpointWebsocketStructGenerationGetSuspense = <
  TData = Awaited<ReturnType<typeof doNotUseThisEndpointWebsocketStructGenerationGet>>,
  TError = AxiosError<HTTPValidationError>,
>(
  doNotUseThisEndpointWebsocketStructGenerationGetBody: DoNotUseThisEndpointWebsocketStructGenerationGetBody,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof doNotUseThisEndpointWebsocketStructGenerationGet>>,
        TError,
        TData
      >
    >
    axios?: AxiosRequestConfig
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDoNotUseThisEndpointWebsocketStructGenerationGetSuspenseQueryOptions(
    doNotUseThisEndpointWebsocketStructGenerationGetBody,
    options,
  )

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
