import { NavLink, Outlet } from 'react-router-dom'
import { useTypedParams } from 'react-router-typesafe-routes/dom'
import { CogIcon, UserPlusIcon, DatabaseIcon, Users, FileJson2Icon, FileKey2Icon } from 'lucide-react'
import { Fragment } from 'react'

import { ROUTES } from '@/packages/router/routes'
import { cn } from '@/packages/style'
import { Content, Main, Sidebar } from '@/components/TabsAndSidebar'
import { Separator } from '@/components/shadcn/ui/separator'
import { useProjectInfo } from '@/packages/api'
import { ProjectIcon } from '@/components/ProjectIcon'

export const ProjectSettingsPage = () => {
  const { organizationName, projectName } = useTypedParams(ROUTES.ORGANIZATION.PROJECT.SETTINGS)

  const { data: projectInfoRes } = useProjectInfo(organizationName, projectName)
  const projectInfo = projectInfoRes?.data

  const projectSettingsLinks: { name: string; href: string; icon: JSX.Element | null; type: 'link' | 'category' }[] = [
    {
      type: 'link',
      name: 'General',
      href: ROUTES.ORGANIZATION.PROJECT.SETTINGS.buildPath({ organizationName, projectName }),
      icon: <CogIcon className="h-4 w-4" />,
    },
    {
      type: 'category',
      name: 'Developer Settings',
      icon: null,
      href: '',
    },
    {
      type: 'link',
      name: 'Setup',
      href: ROUTES.ORGANIZATION.PROJECT.SETTINGS.SETUP.buildPath({ organizationName, projectName }),
      icon: <DatabaseIcon className="h-4 w-4" />,
    },
    {
      type: 'link',
      name: 'Write Tokens',
      href: ROUTES.ORGANIZATION.PROJECT.SETTINGS.WRITE_TOKENS.buildPath({ organizationName, projectName }),
      icon: <FileJson2Icon className="h-4 w-4" />,
    },
    {
      type: 'link',
      name: 'Read Tokens',
      href: ROUTES.ORGANIZATION.PROJECT.SETTINGS.READ_TOKENS.buildPath({ organizationName, projectName }),
      icon: <FileKey2Icon className="h-4 w-4" />,
    },
    {
      type: 'category',
      name: 'Team',
      icon: null,
      href: '',
    },
    {
      type: 'link',
      name: 'List',
      href: ROUTES.ORGANIZATION.PROJECT.SETTINGS.MEMBERS.buildPath({ organizationName, projectName }),
      icon: <Users className="h-4 w-4" />,
    },
    {
      type: 'link',
      name: 'Invite',
      href: ROUTES.ORGANIZATION.PROJECT.SETTINGS.INVITATIONS.buildPath({ organizationName, projectName }),
      icon: <UserPlusIcon className="h-4 w-4" />,
    },
  ]

  return (
    <section className="container">
      <section className="mt-4 flex items-center gap-4 py-4">
        <div className="rounded bg-surface-container p-2">
          <ProjectIcon className="h-8 w-8" />
        </div>
        <div>
          <h2 className="text-xl font-semibold">{projectName}</h2>
          <p className="text-sm text-on-surface-variant">{projectInfo?.description ?? ' '}</p>
        </div>
      </section>
      <Main>
        <Sidebar>
          {projectSettingsLinks.map(({ name, href, icon, type }, i) => {
            return type === 'link' ? (
              <li key={href}>
                <NavLink
                  end={href === ROUTES.ORGANIZATION.PROJECT.SETTINGS.buildPath({ organizationName, projectName })}
                  to={href}
                  className={({ isActive }) =>
                    cn('flex items-center gap-x-1.5 rounded-lg px-2 py-1.5', isActive && 'bg-surface-container-high')
                  }
                >
                  <span>{icon}</span>
                  {name}
                </NavLink>
              </li>
            ) : (
              <Fragment key={`${i}-sep-f`}>
                <Separator className="my-4" key={`${i}-sep`} />
                <li key={i}>
                  <span className="flex cursor-default items-center gap-x-1.5 rounded-lg px-2 py-1.5 text-sm text-foreground text-opacity-70">
                    {name}
                  </span>
                </li>
              </Fragment>
            )
          })}
        </Sidebar>
        <Content>
          <Outlet />
        </Content>
      </Main>
    </section>
  )
}
