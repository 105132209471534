import { formatDistanceStrict, parseISO } from 'date-fns'

import { DateRangePickerValue } from '@/store/monitoring/monitoringType'
import { SimpleDuration } from '@/packages/time/simpleDuration'
import { resolutionsByTimeWindow } from '@/components/DateRangePicker'

function formatSimpleDuration(seconds: number): SimpleDuration {
  if (seconds < 3600) {
    const minutes = Math.round(seconds / 60)
    // Ensure minutes evenly divide an hour
    const adjustedMinutes = [1, 2, 3, 4, 5, 6, 10, 12, 15, 20, 30].find((m) => m >= minutes) ?? 30
    return `${adjustedMinutes}m`
  }
  if (seconds < 86400) {
    const hours = Math.round(seconds / 3600)
    // Ensure hours evenly divide a day
    const adjustedHours = [1, 2, 3, 4, 6, 8, 12].find((h) => h >= hours) ?? 12
    return `${adjustedHours}h`
  }
  const days = Math.round(seconds / 86400)
  return `${Math.max(1, Math.ceil(days / 24) * 24)}d`
}

function calculateResolution(totalSeconds: number): SimpleDuration {
  const targetDataPoints = 80 // Aiming for the middle of 60-100 range
  const resolutionSeconds = Math.max(1, Math.round(totalSeconds / targetDataPoints))
  return formatSimpleDuration(resolutionSeconds)
}

const getResolutionByTimeRange = (range: DateRangePickerValue['range']) => {
  const startDate = parseISO(range.from)
  const endDate = range.to ? parseISO(range.to) : new Date()

  const [secondsInDistance] = formatDistanceStrict(startDate, endDate, { unit: 'second' }).split(' ')

  const calculatedResolution = calculateResolution(parseInt(secondsInDistance))

  return calculatedResolution
}

export const getDefaultResolution = (dateRangePickerValue: DateRangePickerValue) => {
  const preset = dateRangePickerValue.preset
  const isMapped = preset && preset in resolutionsByTimeWindow

  const defaultResolution = isMapped
    ? resolutionsByTimeWindow[preset]
    : getResolutionByTimeRange(dateRangePickerValue.range)

  return defaultResolution
}
