import { LogfireLogoWithBetaBadge } from '@/components/LogfireLogoWithBetaBadge'

export const Footer = () => {
  return (
    <footer id="footer" className="mx-auto mt-16 border-l pb-8 pt-2">
      <div className="flex w-[min(1344px,_90vw)] gap-x-16">
        <LogfireLogoWithBetaBadge className="h-12 text-logfire" badgeClassName="bg-logfire dark:bg-logfire" />
        <ul className="mt-4 flex gap-6 text-sm text-on-surface-variant">
          <li>© 2024 Pydantic Inc.</li>
          <li>
            <a href="https://docs.pydantic.dev/logfire/" target="_blank" rel="noreferrer noopener">
              Docs
            </a>
          </li>
          <li>
            <a href="https://docs.pydantic.dev/logfire/help/" target="_blank" rel="noreferrer noopener">
              Support
            </a>
          </li>
          <li>
            <a href="https://github.com/pydantic/logfire/issues/new/choose" target="_blank" rel="noreferrer noopener">
              Report an issue
            </a>
          </li>
          <li>
            <a href="https://logfirestatus.pydantic.dev/" target="_blank" rel="noreferrer noopener">
              Status
            </a>
          </li>
          <li>
            <a
              href="https://docs.pydantic.dev/logfire/legal/terms_of_service/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Terms
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}
